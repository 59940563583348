import { Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MyProjects: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h2>{t('intro.my_projects')}</h2>
      <p>
        <Chip
          label={t('intro.online_mcq_lite')}
          color="primary"
          component="a"
          href="https://mcq-lite.louisyuen.com"
          target="_blank"
          clickable
        />
      </p>
    </div>
  )
}

export default MyProjects
