import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Intro: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h1>
        {'/**'} {t('intro.welcome_message')} {'*/'}
      </h1>
      <p>#web #fullstack #developer</p>
      <p>
        <Button href="https://github.com/louisyuenll" target="_blank">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </Button>
        <Button href="https://linkedin.com/in/louisyuenll" target="_blank">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </Button>
      </p>
    </div>
  )
}

export default Intro
