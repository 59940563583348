import React from 'react'
import * as styles from './layout-coding.module.css'

const LayoutCoding: React.FC = ({ children }) => {
  return (
    <div className={styles.root}>
      <main className={styles.content}>{children}</main>
    </div>
  )
}

export default LayoutCoding
