import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Intro from '../packages/intro/intro'
import MyProjects from '../packages/intro/my-projects'
import LayoutCoding from '../packages/layout/layout-coding'
import Seo from '../packages/seo/seo'

const IndexPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutCoding>
      <Seo title={t('home')} />
      <Intro />
      <MyProjects />
    </LayoutCoding>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
